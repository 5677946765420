import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Title } from "../styles/baseStyles"
import { GatsbyImage } from "gatsby-plugin-image"

export const teamQuery = graphql`
  {
    team: contentfulTeam {
      title
      desc {
        desc
      }
      members {
        id
        title
        name
        image {
          gatsbyImageData(placeholder: BLURRED, formats: [WEBP, PNG])
        }
        desc {
          desc
        }
      }
    }
  }
`

export default function TeamPage({ data: { team } }) {
  return (
    <TeamContainer>
      <Seo title="Team" />
      <Head>
        <Title>{team.title}</Title>
        <p>{team.desc.desc}</p>
      </Head>
      <Grid>
        {team.members.map(item => (
          <Person key={item.id}>
            <GatsbyImage
              placeholder="blurred"
              image={item.image.gatsbyImageData}
            />
            <Name>{item.name}</Name>
            {item?.desc?.desc && <p>{item.desc.desc}</p>}
          </Person>
        ))}
      </Grid>
    </TeamContainer>
  )
}

const TeamContainer = styled.div`
  margin: 7rem 0 5rem;
  @media screen and (max-width: 1170px) {
    margin: 5rem 5vw 5rem;
  }
`
const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;
  p {
    margin: 1.5rem 0 2.5rem;
    font-size: 1.8rem;
  }
  @media screen and (max-width: 780px) {
    p {
      margin: 1.5rem 0 0.5rem;
      width: 65%;
      text-align: center;
    }
  }
  @media screen and (max-width: 480px) {
    p {
      width: 75%;
    }
  }
`
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8rem;
  justify-content: center;
  @media screen and (max-width: 780px) {
    flex-direction: column;
    row-gap: 5rem;
  }
`
const Person = styled.div`
  flex: 0 0 calc(28%);
  display: flex;
  flex-direction: column;
  align-items: center;
  &:not(:last-child) {
    margin-right: 2rem;
  }
  img {
    height: 18rem;
    width: 18rem;
    border-radius: 50%;
  }
  svg {
    height: 32px;
    width: 32px;
    margin: 2rem 0;
  }
  p {
    text-align: left;
    font-size: 1.6rem;
    width: 70%;
    margin-top: 1.5rem;
    color: #777;
  }
  @media screen and (max-width: 780px) {
    margin-right: 0 !important;
    margin-bottom: 4rem;
    p {
      text-align: justify;
      font-size: 1.7rem;
      width: 70%;
      margin-top: 2rem;
      color: #777;
    }
  }
  @media screen and (max-width: 480px) {
    img {
      height: 20rem;
      width: 20rem;
    }
  }
`
const Name = styled.h3`
  margin-top: 1.5rem;
`
